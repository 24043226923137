<template>
  <dash-page-new
      :title="$t('Templates')"
      :root="$t('Newsletter')"
  >
    <template #header_action>
      <ws-button-group
          :items="navigationItems"
          v-model="category"
      />
    </template>

    <template #default>

      <div class="mt-6">
        <div v-if="itemsFiltered.length === 0 && category ==='saved'">

          <div class="d-flex justify-center mt-16 pt-16">
            <v-icon size="80" :color="wsDARKLIGHT">mdi-bookmark</v-icon>
          </div>

          <h2 class="wsDARKER text-center mt-4" style="font-size: 22px" >
            {{$t('mailer.templates.no_templates_title')}}
          </h2>
          <h5 class="wsDARKER text-center font-weight-regular mt-4">
            {{$t('mailer.templates.no_templates_description')}}
          </h5>

        </div>
        <v-row>
          <v-col v-for="(item,index) in itemsFiltered" :key="item.value + index" cols="4" lg="3">
            <email-template-card
                :ref="`card_${index}`"
                @select="$emit('select' , $event)"
                :entity="item"
                :blank="item.isBlank"
                :scale="scale"
                template-viewer
            />
          </v-col>

        </v-row>
      </div>



    </template>

  </dash-page-new>

</template>

<script>
import emailTemplateCard
  from "@/modules/finemailer/components/emailConstructor/templates/emailTemplateCard";
import {mapActions} from "vuex";

export default {
  name: "MailerTemplates",
  components: {
    emailTemplateCard
  },
  props: {
    newsletterType: {
      type: String,
      default: 'single'
    }
  },
  data() {
    return {
      category: null,
      items: [],
      scale: 0.5

    }
  },
  computed: {
    itemsFiltered() {
      let items = this.COPY(this.items)

      if (this.category) {
        items = items.filter(el => this.category === 'saved' ? el.is_saved : !el.is_saved)
      } else if (!this.category) {
        items.unshift({isBlank: true})
      }


      return items
    },
    navigationItems() {
      return [
        {text: this.$t('All'), value: null},
        {text: this.$t('mailer.saved_templates'), value: 'saved'},
        {text: this.$t('mailer.westudy_templates'), value: 'westudy'}
      ]
    }
  },
  watch: {
    WINDOW_WIDTH() {
      this.calculateCardWidth()
    }
  },
  methods: {
    ...mapActions('finemailer', [
      'GET_NEWSLETTER_DESIGN_TEMPLATES',
    ]),

    calculateCardWidth() {
      let card = this.$refs.card_0
      if (!card) {
        return;
      }
      if (!card[0]) {
        return
      }

      card = card[0].$el

      const width = card.getBoundingClientRect().width

      this.scale = width / 800
    },

    async initPage() {
      let result = await this.GET_NEWSLETTER_DESIGN_TEMPLATES()

      if (!result) {
        this.ERROR()
        return
      }

      this.items = result.items

    }
  },
  async mounted() {
    await this.initPage()
    this.calculateCardWidth()
  }
}
</script>

<style scoped>

</style>